<template lang="pug">
	.badge(:class="classNames") {{ text }}
</template>

<script>
export default {
	name: "Badge",
	props: {
		text: String,
		color: {
			type: String,
			default: 'green',
		},
	},
	computed: {
		classNames() {
			const cn = [
				`--type-${this.color}`,
			]
			return cn
		},
	},
}
</script>

<style lang="scss" scoped>
.badge {
	padding: 5px;
	color: white;

	border-radius: 3px;

	&.--type-green {
		background-color: color(green);
	}

	&.--type-red {
		background-color: color(red);
	}
}
</style>
