<template lang="pug">
	.rating.flex(v-if="rating >= 0")
		Star.icon(v-for="star in fillStars" :key="`fillStars-${star}`")
		StarHalfFull.icon(v-if="isHalfFull")
		StarOutline.icon(v-for="star in emptyStars" :key="`emptyStars-${star}`")
</template>

<script>
import StarOutline from "vue-material-design-icons/StarOutline.vue";
import StarHalfFull from "vue-material-design-icons/StarHalfFull.vue";
import Star from "vue-material-design-icons/Star.vue";

export default {
	name: "Rating",
	components: {
		StarOutline,
		StarHalfFull,
		Star,
	},
	props: {
		rating: [String, Number],
	},
	computed: {
		fillStars() {
			return Math.floor(Number(this.rating));
		},
		isHalfFull() {
			return !Number.isInteger(Number(this.rating));
		},
		emptyStars(){
			return 5 - Math.ceil(Number(this.rating))
		},
	},
}
</script>

<style scoped>
.icon {
	color: #FFBA31;
}
</style>
